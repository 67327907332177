<nav data-cy="navbar" class="navbar navbar-dark navbar-expand-md bg-dark">
  <a class="navbar-brand logo" routerLink="/" (click)="collapseNavbar()">
    <span class="logo-img"></span>
    <span class="navbar-title">Gateway</span>
    <span class="navbar-version">{{ version }}</span>
  </a>
  <a
    class="navbar-toggler d-lg-none"
    href="javascript:void(0);"
    data-toggle="collapse"
    data-target="#navbarResponsive"
    aria-controls="navbarResponsive"
    aria-expanded="false"
    aria-label="Toggle navigation"
    (click)="toggleNavbar()"
  >
    <fa-icon icon="bars"></fa-icon>
  </a>
  <div class="navbar-collapse collapse" id="navbarResponsive" [ngbCollapse]="isNavbarCollapsed" [ngSwitch]="isAuthenticated()">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a class="nav-link" routerLink="/" (click)="collapseNavbar()">
          <span>
            <fa-icon icon="home"></fa-icon>
            <span>Home</span>
          </span>
        </a>
      </li>
      <!-- jhipster-needle-add-element-to-menu - JHipster will add new menu items here -->
      <li
        *ngSwitchCase="true"
        ngbDropdown
        class="nav-item dropdown pointer"
        display="dynamic"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="entity-menu" data-cy="entity">
          <span>
            <fa-icon icon="th-list"></fa-icon>
            <span>Entities</span>
          </span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="entity-menu">
          <!-- jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here -->
        </ul>
      </li>
      <li
        *jhiHasAnyAuthority="'ROLE_ADMIN'"
        ngbDropdown
        class="nav-item dropdown pointer"
        display="dynamic"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="admin-menu" data-cy="adminMenu">
          <span>
            <fa-icon icon="users-cog"></fa-icon>
            <span>Administration</span>
          </span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="admin-menu">
          <li>
            <a class="dropdown-item" routerLink="admin/gateway" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="road" [fixedWidth]="true"></fa-icon>
              <span>Gateway</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/metrics" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="tachometer-alt" [fixedWidth]="true"></fa-icon>
              <span>Metrics</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/health" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="heart" [fixedWidth]="true"></fa-icon>
              <span>Health</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/configuration" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="cogs" [fixedWidth]="true"></fa-icon>
              <span>Configuration</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="admin/logs" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="tasks" [fixedWidth]="true"></fa-icon>
              <span>Logs</span>
            </a>
          </li>
          <li *ngIf="openAPIEnabled">
            <a class="dropdown-item" routerLink="admin/docs" routerLinkActive="active" (click)="collapseNavbar()">
              <fa-icon icon="book" [fixedWidth]="true"></fa-icon>
              <span>API</span>
            </a>
          </li>
          <!-- jhipster-needle-add-element-to-admin-menu - JHipster will add entities to the admin menu here -->
        </ul>
      </li>
      <li
        ngbDropdown
        class="nav-item dropdown pointer"
        display="dynamic"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu" data-cy="accountMenu">
          <span *ngIf="!getImageUrl()">
            <fa-icon icon="user"></fa-icon>
            <span>Account</span>
          </span>
          <span *ngIf="getImageUrl()">
            <img [src]="getImageUrl()" class="profile-image rounded-circle" alt="Avatar" />
          </span>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="account-menu">
          <li *ngSwitchCase="true">
            <a class="dropdown-item" (click)="logout()" id="logout" data-cy="logout">
              <fa-icon icon="sign-out-alt" [fixedWidth]="true"></fa-icon>
              <span>Sign out</span>
            </a>
          </li>
          <li *ngSwitchCase="false">
            <a class="dropdown-item" (click)="login()" id="login" data-cy="login">
              <fa-icon icon="sign-in-alt" [fixedWidth]="true"></fa-icon>
              <span>Sign in</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>
